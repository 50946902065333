@import "../library/Color";

.main{
  min-height: 100vh;
  display: flow-root;
  margin-bottom: 3em;
}

.footer {
  color: white;
  background-color: get-theme-color("dark");
  margin-top: -3em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1006;
  position: relative;
}
