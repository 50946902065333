html, body, html > * > div.full-height {
  height: 100vh;
}

@import "./library/Color";
@import "~bootstrap";

a {
  color: get-theme-color("primary");
}

a:hover {
  color: get-theme-color("primary-shade");
}

a.nav-link, .pointer {
  cursor: pointer;
}

.navbar-dark .navbar-nav .active > .nav-link{
  color: get-theme-color("primary");
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: get-theme-color("primary-shade");
}

.form-control:focus {
  border-color: get-theme-color("primary");
  box-shadow: 0 0 0 0.1rem get-theme-color("primary-shade");
}

.box-shadow {
  box-shadow: 0 0 20px 1px get-theme-color("primary");
}

.vertical-super {
  vertical-align: super;
}
